.bgImage{
    background: url('./../../assets/img/home-banner.png') no-repeat center center;

    
}
.jumbotron-fluid{
    height: 100%;;
}  

.service_title{
    b{
        font-size: 14px;
    }
}