body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn:disabled {
  cursor: default;
  color: #ffffff;
  background-color: #4A90E2;
}
.srv-validation-message{
	 	color: red;
        font-size: 12px;
        margin: 5px 0px 0px 0px;
        padding-left: 20px;
        font-family: "Roboto", sans-serif;
        font-weight: 300;
        padding: 0;
}

.dropdown-menu:li {
  text-align: left;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0; 
}
