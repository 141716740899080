.align-items-center.left-image {
    flex-direction: row-reverse;
    @media(max-width: 576px)
    {
        text-align: center;
    }
}
.text-small-center
{text-align: right;
    @media(max-width: 576px)
    {text-align: center;}
}
.partner-bg{
    .text-small-center-rc {
      text-align: right;
      @media(max-width: 768px)
      {
        text-align:center;
      }
      @media(max-width: 450px)
      {
        font-size:28px;
      }
    }
    .abt-logo-box
    { box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
      padding: 30px 20px;
      text-align: center;
      border: 1px solid #e1e1e1;
      border-radius: 4px;
      background: #fff;
      @media(max-width: 768px)
      {
      margin-bottom: 30px;
      }
      img{width: 90%;
          max-height: 90px;
      }
    }
  }
  @media screen and (min-width: 769px) {
    a.navbar-brand img {
        min-height: 70px;
    }
    }


    .expandBtn{
      .ShowBlock{
        display: none;
      }
      &.collapsed{

      
        .ShowBlock{
          display: block;
        }
        .CloseDetatils{
          display: none;
        }
      }

    }
    
    